import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Wallpapers.css";

const Wallpapers = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const wallpapers = [
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831735/wallpapers/rcg-single-without-cup.png", name: "Wallpaper 1" },
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831734/wallpapers/mcg-single-without-cup.png", name: "Wallpaper 2" },
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831734/wallpapers/rcg-full-with-cup.png", name: "Wallpaper 3" },
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831734/wallpapers/mcg-full-with-cup.png", name: "Wallpaper 4" },
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831733/wallpapers/rcg-lovers-quarrel-cup.jpg", name: "Wallpaper 5" },
        { src: "https://dl.richmondlive.lk/richmond-live/image/upload/v1742831733/wallpapers/mcg-lovers-quarrel-cup.jpg", name: "Wallpaper 6" },
    ];

    const handleWallpaperClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <motion.div className="bg-wrapper">
            <motion.div
                className="logo"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            >
                <img src="/playground_assets/RL-White.png" alt="Richmond Live Logo" />
            </motion.div>

            <motion.div
                className="title"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            >
                <h1>Lovers' Quarrel Wallpapers Pack</h1>
            </motion.div>

            <motion.p
                className="description"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
            >
                Download the 120th Richmond-Mahinda Lovers' Quarrel wallpapers pack for your mobile devices.
            </motion.p>

            <motion.a
                href="https://github.com/richmond-live/public-assets/raw/refs/heads/main/Lovers'%20Quarrel%202025%20Wallpapers%20by%20Richmond%20Live.zip"
                className="download-all-btn"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
            >
                Download All
            </motion.a>

            <motion.div
                className="wallpapers-grid"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
            >
                {wallpapers.map((wallpaper, index) => (
                    <div
                        key={index}
                        className="wallpaper"
                        onClick={() => handleWallpaperClick(index)}
                    >
                        <img
                            src={wallpaper.src}
                            alt={wallpaper.name}
                        />
                        <a
                            href={wallpaper.src}
                            className={`download-btn ${activeIndex === index ? "visible" : ""}`}
                            download={wallpaper.name}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download
                        </a>
                    </div>
                ))}
            </motion.div>

            <div className="footer">
                <p>A Project By Team Richmond Live</p>
            </div>
        </motion.div>
    );
};

export default Wallpapers;